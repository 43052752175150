import dynamic from 'next/dynamic';
import { type MutableRefObject, useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import { useInView } from 'framer-motion';
import { useBreakpoint } from 'light-react-grid';
import { type YouTubePlayer } from 'react-youtube';

import { AnalyticsSpotlight, Event, trackEvent } from '~/ui/components/analytics';
import { FullBleedCTA, FullBleedCTATrigger } from '~/v1/components/fullBleedCta/fullBleedCta';
import { SpotlightVideo, SpotlightYouTubeVideo } from '~/v1/components/spotlight/video/video';
import { Breakpoint } from '~/v1/constants/breakpoint';
import { useOnScreen } from '~/v1/hooks/useOnScreen';

import { type SpotlightType } from './spotlight.interface';
import styles from './spotlight.module.scss';
import { formatDuration } from './spotlight.utils';
import { Button } from '../button/button';
import { ButtonMode, ButtonSize, ButtonType } from '../button/button.interface';
import { Column } from '../grid/column/column';
import { Grid } from '../grid/grid';
import { Icon } from '../icons/icon';
import { IconType } from '../icons/icon.interfaces';
import { Image } from '../image/image';
import { ImageBreakpoint } from '../image/image.interface';
import { Modal } from '../modal/modal';

const SpotlightSlideshow = dynamic(() =>
  import('./slideshow/slideshow').then(mod => mod.SpotlightSlideshow),
);

interface SpotlightProps {
  className?: string;
  spotlight: SpotlightType;
  priority?: boolean;
}

export function Spotlight({ spotlight, className, priority }: SpotlightProps): React.ReactElement {
  const {
    headline,
    eyebrow,
    buttonIcon,
    buttonLabel,
    videoUrl,
    videoId,
    videoCaptionsUrl,
    previewVideoUrl,
    images,
    staticImage,
  } = spotlight;
  const [isBackgroundPaused, setIsBackgroundPaused] = useState<boolean>(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const fullVideoRef = useRef<HTMLVideoElement>(null);
  const [fullYouTubeVideo, setFullYouTubeVideo] = useState<YouTubePlayer>();

  const previewVideoRef = useRef<HTMLVideoElement>(null);
  const [previewYouTubeVideo, setPreviewYouTubeVideo] = useState<YouTubePlayer>();
  const isPreviewVideoInView = useInView(previewVideoRef);
  // This wrapper is necessary since the ref cannot be created on the
  // YouTubePlayer itself
  const previewYouTubeVideoWrapperRef = useRef<HTMLDivElement>(null);
  const isPreviewYouTubeVideoInView = useInView(previewYouTubeVideoWrapperRef);

  const breakpoint = useBreakpoint();
  const { inView, ref } = useOnScreen();

  useEffect(() => {
    inView && setShouldAnimate(true);
  }, [inView]);

  useEffect(() => {
    if (isPreviewVideoInView) {
      previewVideoRef.current?.play();
    } else {
      previewVideoRef.current?.pause();
    }
  }, [isPreviewVideoInView]);

  useEffect(() => {
    // "h" is the metadata of the YouTube video that is populated when it is
    // fully loaded and ready to be played. Without this check, the video may
    // not be fully loaded yet by the time it is in view and result in an error.
    if (previewYouTubeVideo && previewYouTubeVideo.h) {
      if (isPreviewYouTubeVideoInView) {
        previewYouTubeVideo?.playVideo();
      } else {
        try {
          previewYouTubeVideo?.pauseVideo();
        } catch (e) {
          // No-op. An exception occurs here on page load, but it does not affect
          // the ability to pause the video. Instead of letting it crash the page,
          // ignore the exception and continue.
        }
      }
    }
  }, [previewYouTubeVideo, isPreviewYouTubeVideoInView]);

  const toggleBackground = () => {
    setIsBackgroundPaused(!isBackgroundPaused);

    if (isBackgroundPaused) {
      previewVideoRef.current?.play();
      previewYouTubeVideo?.playVideo();
    } else {
      previewVideoRef.current?.pause();
      previewYouTubeVideo?.pauseVideo();
    }
  };

  const openModal = () => {
    trackEvent(Event.SPOTLIGHT_CLICK, {
      spotlightType: videoUrl || videoId ? AnalyticsSpotlight.VIDEO : AnalyticsSpotlight.SLIDESHOW,
      spotlightTitle: headline,
    });

    setIsModalOpen(true);

    if (fullVideoRef.current) {
      fullVideoRef.current.currentTime = 0;
      fullVideoRef.current.play();
    }

    if (fullYouTubeVideo) {
      fullYouTubeVideo.seekTo(0);
      fullYouTubeVideo.playVideo();
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);

    if (fullVideoRef.current) {
      fullVideoRef.current.pause();
      fullVideoRef.current.currentTime = 0;
    }

    if (fullYouTubeVideo) {
      fullYouTubeVideo.pauseVideo();
      fullYouTubeVideo.seekTo(0);
    }
  };

  const size = videoUrl
    ? formatDuration(fullVideoRef.current?.duration)
    : videoId
      ? formatDuration(fullYouTubeVideo?.getDuration())
      : images
        ? `${images.length} photo${images.length > 1 ? 's' : ''}`
        : '';
  const Headline = Breakpoint.SM !== breakpoint ? 'h2' : 'h3';
  const showToggleButton = previewVideoUrl || (!staticImage && !images);

  return (
    <div className={cx(styles.spotlight, className)}>
      <div className={styles.spotlightInner} ref={ref as MutableRefObject<HTMLDivElement | null>}>
        {previewVideoUrl ? (
          <SpotlightVideo ref={previewVideoRef} src={previewVideoUrl} isPreview />
        ) : staticImage ? (
          <Image
            aspectRatio="5/4"
            className={cx(styles.image, { [styles.imageZoomIn]: shouldAnimate })}
            priority={priority}
            colSpan={{
              [ImageBreakpoint.SM]: 5,
              [ImageBreakpoint.MD]: 7,
              [ImageBreakpoint.LG]: 9,
            }}
            src={staticImage.src}
            alt={staticImage.alt}
          />
        ) : videoUrl ? (
          <SpotlightVideo ref={previewVideoRef} src={videoUrl} isPreview />
        ) : (
          videoId && (
            <div ref={previewYouTubeVideoWrapperRef}>
              <SpotlightYouTubeVideo
                videoId={videoId}
                isPreview
                getYouTubeVideoRef={setPreviewYouTubeVideo}
              />
            </div>
          )
        )}

        {images && (
          <Image
            {...images[0]}
            aspectRatio="5/4"
            className={cx(styles.image, { [styles.imageZoomIn]: shouldAnimate })}
            priority={priority}
            colSpan={{
              [ImageBreakpoint.SM]: 5,
              [ImageBreakpoint.MD]: 7,
              [ImageBreakpoint.LG]: 9,
            }}
            src={images[0].src}
            alt={images[0].alt}
          />
        )}
        <div className={styles.gradient} />
        {showToggleButton && (
          <Button
            isTransparent
            size={ButtonSize.Icon}
            className={styles.button}
            type={ButtonType.Secondary}
            mode={ButtonMode.Light}
            aria-label={isBackgroundPaused ? 'Play background' : 'Pause background'}
            onClick={toggleBackground}
          >
            <Icon
              aria-hidden
              className={styles.iconPlayPause}
              type={isBackgroundPaused ? IconType.Play : IconType.Pause}
            />
          </Button>
        )}
      </div>
      <FullBleedCTA label={headline} className={styles.content}>
        <Grid>
          <Column sm={5} md={5} lg={5} offsetLeft={{ lg: 1 }}>
            {eyebrow && <div className={styles.eyebrow}>{eyebrow}</div>}
            {headline && (
              <Headline className={cx('truncate-3', 'modulesTitleTypography', styles.headline)}>
                {headline}
              </Headline>
            )}
            <FullBleedCTATrigger>
              <Button
                type={ButtonType.Primary}
                mode={Breakpoint.SM === breakpoint ? ButtonMode.Light : ButtonMode.Dark}
                ariaLabel={buttonLabel}
                onClick={openModal}
              >
                {buttonIcon && <Icon className={styles.icon} type={buttonIcon} />}
                {buttonLabel}
                <span
                  aria-label={videoUrl || videoId ? `Total time ${size}` : `${size} in gallery`}
                  className={styles.buttonInfo}
                >
                  {size}
                </span>
              </Button>
            </FullBleedCTATrigger>
            <Modal
              isModalOpen={isModalOpen}
              className={cx({ [styles.modalVideo]: videoUrl })}
              closeModal={closeModal}
            >
              {videoUrl ? (
                <SpotlightVideo
                  ref={fullVideoRef}
                  src={videoUrl}
                  captionsUrl={videoCaptionsUrl}
                  autoPlay={isModalOpen}
                  controls
                  loop={false}
                  muted={false}
                  preload="metadata"
                />
              ) : videoId ? (
                <SpotlightYouTubeVideo
                  videoId={videoId}
                  autoPlay={isModalOpen}
                  controls
                  loop={false}
                  muted={false}
                  getYouTubeVideoRef={setFullYouTubeVideo}
                />
              ) : null}
              {images && <SpotlightSlideshow slideshow={[...images]} isOpen={isModalOpen} />}
            </Modal>
          </Column>
        </Grid>
      </FullBleedCTA>
    </div>
  );
}
