import { type Spotlight as SpotlightType } from '~/v1/_types/Spotlight';
import { Column } from '~/v1/components/grid/column/column';
import { Grid } from '~/v1/components/grid/grid';
import { Spotlight } from '~/v1/components/spotlight/spotlight';
import { mapSpotlight } from '~/v1/components/spotlight/spotlight.utils';

import styles from './spotlight.module.scss';

interface SpotlightModuleProps {
  spotlight: SpotlightType;
  priority?: boolean;
  className?: string;
}

export const SpotlightModule: React.FC<SpotlightModuleProps> = ({
  spotlight,
  priority,
  className,
}) => {
  return (
    <Grid className={className ?? 'section'}>
      <Column sm={5} md={7} lg={9}>
        <Spotlight
          priority={priority}
          spotlight={mapSpotlight(spotlight)}
          className={styles.spotlight}
        />
      </Column>
    </Grid>
  );
};
